<template>
    <div>
        <div class="table_content">

            <div class="search">
                <el-form ref="form" :model="form" label-width="120px" :inline="true">
                    <el-form-item label="请输入关键字:">
                        <el-input v-model="form.name" placeholder="户室编号" size="small"></el-input>
                    </el-form-item>
                    <button @click.prevent="sear" style="cursor: pointer;">搜索</button>
                </el-form>
            </div>

            <el-card>
                <el-table :data="tableData"
                          style="width:100%"
                          
                          :default-sort="{prop: 'settingTime', order: 'descending'}"
                          :stripe="true"
                          v-loading="loading"
                          element-loading-text="拼命加载中"
                >
                    <el-table-column fixed prop="villageName" label="小区名称" width="200">
                    </el-table-column>
                    <el-table-column fixed prop="towerNum" label="楼幢号" width="200">
                    </el-table-column>
                    <el-table-column prop="unitName" label="单元名称" width="200">
                    </el-table-column>
                    <el-table-column prop="houseNum" label="户室编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="houseAmt" label="金额" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            prop="coveredArea"
                            label="建筑面积"
                            show-overflow-tooltip
                    >
                    </el-table-column>

                    <el-table-column
                            prop="housePurchase"
                            label="购房款"
                            show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                    <el-button @click="back" size="small" class="btn">上一步</el-button>
                </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "orderDetail",
        data() {
            return {
                loading: true,
                url: this.$Config.base_server,
                tableData: [],//表格数据
                totalNum: 15,
                url: this.$Config.base_server,
                form: {
                    name: "",
                    region: '',
                }
            }
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                const _this = this;
                const data = {
                    access_token: localStorage.getItem("token"),
                    orderNo: localStorage.getItem("orderNo")
                }
                //查询当前登录者的订单信息
                $.ajax({
                    url: _this.url + "/api-public/busiorder/detailList",
                    type: 'GET',
                    data: data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                    },
                    error: function (err) {
                        _this.loading = false;
                        console.log(err)
                    }
                });
            }, handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, formatTime(row) {
                //格式化时间
                let date = new Date(row.creatTime);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return date.getFullYear() + "-" + month + "-" + day + "-" + hour + ":" + minutes + ":" + second;
            }, sear(currentPage = 0) {
                //搜索按钮
                //初始化数据
                const _this = this;
                _this.loading = true;
                const data = {
                    access_token: localStorage.getItem("token"),
                    orderNo: localStorage.getItem("orderNo"),
                    searchVal: _this.form.name
                }
                //查询当前登录者的订单信息
                $.ajax({
                    url: _this.url + "/api-public/busiorder/detailList",
                    type: 'GET',
                    data: data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                    }
                });
            }, back() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>